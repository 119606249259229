.list-experience{
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 16px 0;
}

.list-experience .marker{
    width: 4px;
    height: 50px;
    background-color: #8daef1;
    margin-right: 16px;
}

.list-experience .experience{
    width: 100%;
    overflow: hidden;
    display: flex;
    color: white;
}

.list-experience .title{
    display: block;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 4px;
}

.list-experience .info{
    font-size: 18px ;
    margin-bottom: 4px;
}

.list-experience .date{
    font-size: 14px;
    color: rgba(255,255,255,80%);
}

.list-experience .line{
    height: 1px;
    width: 100px;
    background-color: white;
}

.list-experience .actions{
    position: absolute;
    right: 0;
}
