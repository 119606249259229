form.edit-project {
  margin: 8px 0;
  max-width: 600px;
  box-sizing: border-box;
}

form.edit-project .input-group {
  margin: 0 0 32px 0;
  flex: 1;
}

form.edit-project .container-full{
  width: 100%;
}

form.edit-project .container-full .input-group{
  width: 100%;
}

form.edit-project #btn-add-project {
  width: 200px;
  margin-left: auto;
  display: block;
}

form.edit-project .container-tags{
    padding: 16px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
}

form.edit-project .container-tags .tag{
    background-color: rgb(25, 166, 168);
    padding: 8px 16px;
    border-radius: 30px;
    color: white;
    font-size: 13px;
}
