section#projects{
    display: flex;
    gap: 32px;
}

section#projects article{
    margin: 0 0 32px 0;
    flex: 1;
    overflow: hidden;
}

section#projects article h2{
    font-size: 24px;
    font-weight: 600;
}

@media screen and (max-width: 1280px) {
    section#projects{
        display: flex;
        gap: 32px;
        flex-direction: column-reverse;
    }
}


