main.admin-page{
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left: 100px;
}

main .container-section{
    flex: 1;
    height: 100vh;
    padding: 24px;
    overflow-y: auto;
    color: white;
}

main .container-section section{
    flex: 1;
}
