.reorder-modal .card{
    width: 700px;
    max-width: 90%;
}

.reorder-modal .item{
    border: 1px solid rgb(110, 14, 189);
    border-radius: 4px;
    margin-bottom: 16px;
    cursor: pointer;
    user-select: none;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    top: 0;
}

.reorder-modal .item:not(.active){
    transition: 0.2s linear;
}

.reorder-modal .item.no-transition:not(.active){
    transition: none !important;
}

/* .reorder-modal .item:hover{
    background-color: rgb(122, 69, 207);
    border-color: rgb(122, 69, 207);
    color: white;
} */

.reorder-modal .item.active{
    background-color: rgb(122, 69, 207);
    border-color: rgb(122, 69, 207);
    color: white;
}

.reorder-modal h4{
    font-size: 24px;
    text-align: center;
    margin-bottom: 24px;
    font-weight: bold;
}