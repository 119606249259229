section#experience-section{
    display: flex;
    gap: 32px;
}

section#experience-section article{
    margin: 0 0 32px 0;
    flex: 1;
    overflow: hidden;
}

section#experience-section article h2{
    font-size: 24px;
    font-weight: 600;
}

section#experience-section .input-group{
    width: 100%;
}