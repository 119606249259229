nav{
    background-color: rgb(255, 255, 255);
    height: 100%;
    border-radius: 4px;
    padding: 8px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

nav ul{
    list-style: none;
}

nav ul li a{
    padding: 24px;
    cursor: pointer;
    align-items: center;
    display: flex;
    position: relative;
    font-size: 26px;
}

nav ul li a label{
    z-index: 10000;
    position: absolute;
    left: calc(100% + 16px);
    font-size: 14px;
    background-color: white;
    padding: 8px 16px;
    border-radius: 4px;
    color: #053b6d;;
    font-weight: 500;
    transform: translateX(-32px);
    opacity: 0;
    transition: cubic-bezier(1, 0.2, 0.1, 1.2) all .4s;
}

nav ul li a svg{
    z-index: 2;
}

nav ul li a:hover svg, nav ul li a.active svg{
    color: white;
}

nav ul li a:hover label{
    transform: translateX(0px);
    opacity: 100;
}

nav ul li a::before{    
    content: "";
    position: absolute;
    left: 38px;
    right: 38px;
    bottom: 8px;
    top: 8px;
    background-color: #053b6d;
    z-index: 0;
    border-radius: 12px;
    opacity: 0%;
    transition: ease-in all .4s;
    transform: translateY(-30px);
}

nav ul li a:hover::before, nav ul li a.active::before{
    opacity: 100%;
    transform: translate(0);
    left: 8px;
    right: 8px;
    bottom: 8px;
    top: 8px;
}


nav header button{
    background-color: rgb(191, 69, 8);
    color: white;
    box-shadow: rgba(0,0,0,0.4);
    display: block;
    margin-left: auto;
    flex: 1;
}

nav header button.btn-edit{
    background-color: rgb(12, 92, 138);
}
