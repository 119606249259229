.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a{
  text-decoration: none;
  color: inherit;
}

