.container-alert{
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: white;
}

.container-alert .alert{
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px 32px;
}

.container-alert .alert.success{
    background-color: rgb(73, 211, 142);
}

.container-alert .alert.error{
    background-color: rgb(175, 63, 41);
}

.container-alert  .alert.warning{
    background-color: rgb(175, 108, 41);
}