button#open-add-project{
  position: absolute;
  bottom: 24px;
  right: 32px;
}

form#form-add-project {
  width: 100%;
  margin: 8px 0;
  position: absolute;
  width: fit-content;
  padding: 32px;
  height: 700px;
  overflow: auto;
  background-color: rgb(28, 15, 56);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

form#form-add-project::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

form .input-group {
  margin: 0 0 32px 0;
  flex: 1;
}

form .container-full {
  width: 100%;
}

form .container-full .input-group {
  width: 100%;
}

form #btn-add-project {
  width: 200px;
  margin-left: auto;
  display: block;
}

form .container-tags {
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

form .container-tags .tag {
  background-color: rgb(25, 166, 168);
  padding: 8px 16px;
  border-radius: 30px;
}
