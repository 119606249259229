.container-loading-bar .loading-bar {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-loading-bar .loading-bar .title {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
  font-size: 12px;
}

.container-loading-bar .loading-bar svg {
  width: 200px;
  height: 200px;
}

.container-loading-bar .loading-bar .circle {
  cx: 100;
  cy: 100;
  r: 70;
  fill: none;
  stroke: rgb(97, 245, 183);
  stroke-width: 3px;
  stroke-dasharray: 110;
  stroke-dashoffset: 20;
  animation: loading-animation 1s linear infinite;
  transform-origin: center center;
}

@keyframes loading-animation {
  0% {
    stroke-dasharray: 110;
    stroke-dashoffset: 20;
    transform: rotate(0deg);
  }

  100% {
    stroke-dasharray: 110;
    stroke-dashoffset: 20;
    transform: rotate(360deg);
  }
}
