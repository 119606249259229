.login-page{
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 32px;
}

main .card{
    width: 400px;
    max-width: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 2px 6px 8px rgba(0,0,0,0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 16px;
    text-align: center;
}

main .card img{
    margin-bottom: 16px;
}

main .card form{
    display: flex;
    flex-direction: column;
    margin: 8px 0 0 0;
}

main .card form h2{
    margin-bottom: 8px;
    margin-left: 16px;
    font-weight: 600;
}

main .card form p{
    font-size: 14px;
    color: rgba(0,0,0,0.8);
    margin: 0 32px;
    margin-bottom: 8px;
}

main .card input{
    margin: 8px 16px;
}

main .card button{
    width: fit-content;
    padding: 0 48px;
    margin: 24px 0 0 auto;
}

main .card span{
    font-size: 12px;
    margin: 16px 0 0 0;
}

main .card a{
    color: #0c2a68;
    font-weight: bold;
}
