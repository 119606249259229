@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Permanent+Marker&family=Poppins:wght@400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utitilites{
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.input-group {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.input-group label {
  font-size: 14px;
  margin-bottom: 12px;
}

input {
  height: 40px;
  border-radius: 4px;
  border: 0;
  outline: none;
  padding: 0 16px;
  color: black;
}

input.outlined, textarea.outlined {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: black;
}

input.underlined, textarea.underlined {
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  color: black;
  border-radius: 0px;
  padding: 0 8px;
}

input.underlined-dark {
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 0px;
  padding: 0 8px;
  color: white;
}

input.outline-dark {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: white;
  /* box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.15); */
}

input.outline-dark::placeholder,
input.underlined-dark::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

textarea {
  resize: vertical;
  border-radius: 6px;
  padding: 16px;
  outline: none;
  height: 150px;
  color: black;
}

textarea.outline-dark {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: white;
  box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.15);
}

input.border {
  border: 1px solid black;
}

input.underlined {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 0;
  padding: 0;
}

button,
.button {
  height: 40px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  padding: 0px 32px;
  transition: all 0.2s linear;
}

button:active,
.button.active {
  transform: scale(0.95);
}

button.primary,
.button.primary {
  background-color: #282c34;
  color: white;
}

button.primary-dark,
.button.primary-dark,
.circle-button.primary-dark {
  background-color: #2054bb;
  color: white;
}

button.secondary-dark,
.button.secondary-dark,
.circle-button.secondary-dark {
  background-color: #8742b5;
  color: white;
}


button.error{
  background-color: #bb4420;
  color: white;
}

.circle-button {
  width: 35px;
  height: 35px;
  padding: 0;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-button svg {
  margin: 0 auto;
}

.container-horizontal {
  display: flex;
  gap: 24px;
}

.card{
  background: white;
  width: fit-content;
  padding: 32px;
  color: black;
  border-radius: 8px;
  box-shadow: 2px 4px 8px rgba(0,0,0, 0.4);
  overflow: auto;
  max-height: 90%;
}
