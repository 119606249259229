.select-multiple{
    position: relative;
}

.select-multiple .container-value{
    position: absolute;
    top: 100%;
    width: 100%;
    overflow:hidden ;
    border: 1px solid gray;
    max-height: 300px;
    overflow: auto;
    z-index: 100;
}

.select-multiple .container-value .value{
    padding: 12px 8px;
    background-color: white;
    color: black;
    width: 100%;
    cursor: pointer;
    transition: 0.2s linear;
}

.select-multiple .container-value .value.choosed, .select-multiple .container-value .value:hover{
    background-color: #2054bb;
    color: white;
}