.switch .input-switch{
    display: none;
}

.switch label{
    width: 120px;
    height: 50px;
    background-color: rgb(251, 99, 99);
    display: block;
    border-radius: 30px;
    position: relative;
    cursor: pointer;
    transition: .2s linear;
}

.switch label::before{
    content: "";
    position: absolute;
    width: 38px;
    height: 38px;
    background-color: rgb(255, 255, 255);
    top: 6px;
    left: 6px;
    border-radius: 20px;
    transition: .2s linear;
}

.switch .input-switch.checked ~ label{
    background-color: rgb(99, 223, 138);
}

.switch .input-switch.checked ~ label::before{
    left: calc(100% - 38px - 6px);
}