section#profile{
  display: flex;
  flex-direction: column;
  gap: 32px;
}

section#profile article{
  margin: 0 0 32px 0;
  overflow: hidden;
}

section#profile article h2{
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}

section#profile .container-horizontal{
  display: flex;
}

section#profile .container-horizontal > *:first-child{
  flex: 1;
}

section#profile .input-group{
  width: 100%;
}

section#profile th, section#profile td{
  padding: 8px;
  text-align: start;
}

section#profile .table-statistic{
  max-height: 300px;
  overflow: auto;
  padding: 0 16px 0 0;
}

section#profile .container-activity{
  max-height: 300px;
  overflow: auto;
  padding: 0 16px 0 0;
}

section#profile .container-activity .activity{
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  margin-bottom: 16px;
  border-radius: 8px;
  /* border:1px solid white; */
}

section#profile .container-activity .activity a{
  text-decoration: underline;
}



@media screen and (max-width: 1280px) {
  section#profile{
      display: flex;
      gap: 32px;
      flex-direction: column-reverse;
  }
}
