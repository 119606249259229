.container-project{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
    margin: 16px 0;
}

.container-project .project{
    width: 100%;
    /* background-color: white; */
    overflow: hidden;
    color: black;
    /* box-shadow: 0px 0px 8px rgba(255,255,255,0.25); */
    display: flex;
    color: white;
    padding-bottom: 32px;
}

.container-project .project:not(:last-child){
    border-bottom: 1px solid rgba(255, 255, 255,0.4);
}

.container-project img{
    width: 320px;
    height: auto;
    display: block;
    border-radius: 8px;
}

.container-project .detail{
    margin: 0 0 0 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.container-project .title{
    display: block;
    font-size: 20px;
    margin-bottom: 8px;
    font-weight: bold;
}

.container-project .description{
    font-size: 14px;
    max-height: 4.2em;
    line-height: 1.4em;
    overflow: hidden;
    word-break: break-all;
    font-weight: 400;
    color: rgba(255, 255, 255,0.9);
}

.container-project .detail .actions{
    display: flex;
    margin-top: auto;
    margin-left: auto;
    gap: 12px;
}